import React from 'react'
import AppRoutes from '@components/Routes/AppRoutes'
import { ToastContainer, Slide } from 'react-toastify'

import './App.scss'

function App() {
  return (
    <>
      <AppRoutes />
      <ToastContainer
        position='top-center'
        autoClose={3000}
        closeButton={false}
        transition={Slide}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </>
  )
}

export default App
