import React from 'react'
import { Outlet } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Navigation from '@components/Navigation/Navigation'

function Layout() {
  return (
    <>
      <Navigation />
      <Container fluid='xl' className='py-5'>
        <Outlet />
      </Container>
    </>
  )
}

export default Layout
