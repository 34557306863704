import { gql } from '@apollo/client'
import MERCHANT_DETAILS_FIELDS from '@graphql/fragments/merchant-details-fields'

const GET_MERCHANT = gql`
  ${MERCHANT_DETAILS_FIELDS}
  query GetMerchant($id: ID!) {
    merchant(id: $id) {
      ...MerchantDetailsFields
    }
  }
`

export default GET_MERCHANT
