import React, { useState } from 'react'
import { createSearchParams } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import DatetimeField from '@components/DatetimeField/DatetimeField'
import SearchAccordion from '@components/Search/Accordion'
import SearchActions from '@components/Search/Actions'

function Search({ refetch, searchFilters, setSearchParams }) {
  const SEARCH_FILTERS = {
    status_eq: '',
    currency_eq: '',
    created_at_gteq: '',
    created_at_lteq: ''
  }
  const [filters, setFilters] = useState({
    ...SEARCH_FILTERS,
    ...searchFilters
  })

  return (
    <SearchAccordion searchFilters={searchFilters}>
      <Form>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={2} className='text-end'>
            Created at
          </Form.Label>
          <Col sm={4}>
            <InputGroup>
              <InputGroup.Text>From</InputGroup.Text>
              <DatetimeField
                name='created_at_gteq'
                value={filters.created_at_gteq}
                onChange={(name, value) => setFilters({ ...filters, [name]: value })}
              />
            </InputGroup>
          </Col>
          <Col sm={4}>
            <InputGroup>
              <InputGroup.Text>To</InputGroup.Text>
              <DatetimeField
                name='created_at_lteq'
                value={filters.created_at_lteq}
                onChange={(name, value) => setFilters({ ...filters, [name]: value })}
              />
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={2} className='text-end'>
            Status
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              name='status_eq'
              value={filters.status_eq}
              onChange={(event) => setFilters({ ...filters, status_eq: event.target.value })}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={2} className='text-end'>
            Currency
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              name='currency_eq'
              value={filters.currency_eq}
              onChange={(event) => setFilters({ ...filters, currency_eq: event.target.value })}
            />
          </Col>
        </Form.Group>

        <SearchActions
          onReset={() => {
            setSearchParams({})
            setFilters(SEARCH_FILTERS)
          }}
          onSearch={() => {
            setSearchParams(createSearchParams(filters))
            refetch({ filters })
          }}
        />
      </Form>
    </SearchAccordion>
  )
}

export default Search
