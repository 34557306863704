import { gql } from '@apollo/client'

/*
 * This mutation is responsible for posting the
 * Auth Code (received from the Auth service)
 * to the BSP Processor in order to check its validity
 */

const VERIFY_AUTH_CODE = gql`
  mutation VerifyAuthCode ($code: String!) {
    verifyAuthCode(code: $code) {
      login
      role
    }
  }
`

export default VERIFY_AUTH_CODE
