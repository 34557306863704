import { user } from '@cache'

/*
 * Store the User data when user is logged in
 */

const USER_STORAGE_KEY = 'user'

const User = {
  clear: (key = USER_STORAGE_KEY) => {
    localStorage.removeItem(key)
    user(null)
  },

  get: (key = USER_STORAGE_KEY) => JSON.parse(localStorage.getItem(key)),

  set: (value, key = USER_STORAGE_KEY) => {
    localStorage.setItem(key, JSON.stringify(value))
    user(value)
  }
}

export default User
