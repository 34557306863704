import { gql } from '@apollo/client'

const GET_MISCELLANEOUS_TAX = gql`
  query GetMiscellaneousTax($id: ID!) {
    miscellaneousTax(id: $id) {
      id
      createdAt
      updatedAt
      amount
      currency
      taxType
      ticketDocumentNumber
      transactionId
    }
  }
`

export default GET_MISCELLANEOUS_TAX
