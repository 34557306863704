import React from 'react'
import Alert from 'react-bootstrap/Alert'

function Error({ error }) {
  return (
    <Alert variant='danger' className='alert-danger-light'>
      <Alert.Heading>Something went wrong!</Alert.Heading>
      <p>{error.message}</p>
      <hr />
      <p className='mb-0'>Please, contact tech-support!</p>
    </Alert>
  )
}

export default Error
