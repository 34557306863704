import React from 'react'

function DeleteMerchantModalBody({ name }) {
  return (
    <>
      { 'You are going to delete ' }
      <strong>{name}</strong>.
    </>
  )
}

export default DeleteMerchantModalBody
