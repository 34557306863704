import { gql } from '@apollo/client'

const UPDATE_MERCHANT = gql`
  mutation UpdateMerchant($id: ID!, $name: String!, $airlineAgreementNumber: String!, $categoryCode: Int!) {
    updateMerchant(id: $id, name: $name, airlineAgreementNumber: $airlineAgreementNumber, categoryCode: $categoryCode) {
      id
      name
      updatedAt
      airlineAgreementNumber
      categoryCode
    }
  }
`

export default UPDATE_MERCHANT

