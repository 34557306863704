import React, { useState } from 'react'
import { createSearchParams } from 'react-router-dom'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import SearchAccordion from '@components/Search/Accordion'
import SearchActions from '@components/Search/Actions'

function Search({ refetch, searchFilters, setSearchParams }) {
  const SEARCH_FILTERS = {
    name_cont: ''
  }
  const [filters, setFilters] = useState({
    ...SEARCH_FILTERS,
    ...searchFilters
  })

  return (
    <SearchAccordion searchFilters={searchFilters}>
      <Form>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label column sm={2} className='text-end'>
            Name
          </Form.Label>
          <Col sm={4}>
            <Form.Control
              name='name_cont'
              value={filters.name_cont}
              onChange={(event) => setFilters({ ...filters, name_cont: event.target.value })}
            />
          </Col>
        </Form.Group>

        <SearchActions
          onReset={() => {
            setSearchParams({})
            setFilters(SEARCH_FILTERS)
          }}
          onSearch={() => {
            setSearchParams(createSearchParams(filters))
            refetch({ filters })
          }}
        />
      </Form>
    </SearchAccordion>
  )
}

export default Search
