import { ApolloClient, createHttpLink, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { cache } from '@cache'
import User from '@utils/storage/user'

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions, locations, path }) => {
      console.warn(
        `[GraphQL error]: Message: ${message},`,
        `Extensions: ${extensions},`,
        `Location: ${locations},`,
        `Path: ${path}`
      )

      if (extensions?.code === 'UNAUTHORIZED') {
        User.clear()
      }
    })
  }

  if (networkError) { console.warn(`[Network error]: ${networkError}`) }
})

const httpLink = createHttpLink({
  uri: Settings.GRAPHQL_ENDPOINT,
  credentials: 'include'
})

const apolloClient = new ApolloClient({
  cache,
  link: from([errorLink, httpLink])
})

export default apolloClient
