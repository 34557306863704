import { gql } from '@apollo/client'

const GET_INVOICES = gql`
  query GetInvoices($filters: JSON) {
    invoices(filters: $filters) {
      collection {
        id
        createdAt
        date
        name
        number
        merchant {
          id
          name
        }
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_INVOICES
