import { gql } from '@apollo/client'

const GET_PAYMENT_PLANS = gql`
  query GetPaymentPlans($filters: JSON) {
    paymentPlans(filters: $filters) {
      collection {
        id
        createdAt
        currency
        invoiceNumber
        ticketDocumentNumber
        totalAmount
        transactionId
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_PAYMENT_PLANS
