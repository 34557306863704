import React, { useState } from 'react'
import Alert from 'react-bootstrap/Alert'
import BsForm from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

function Form({ merchant, onSubmit, result, submitButtonLabel }) {
  const [name, setName] = useState(merchant.name)
  const [airlineAgreementNumber, setAirlineAgreementNumber] = useState(merchant.airlineAgreementNumber)
  const [categoryCode, setCategoryCode] = useState(merchant.categoryCode)

  const submit = (event) => {
    event.preventDefault()
    onSubmit({ name, airlineAgreementNumber, categoryCode })
  }

  return (
    <Row>
      <Col md='6' lg='7'>
        <BsForm onSubmit={submit}>
          <Card>
            <Card.Body>
              <Card.Title>Merchant Configurations</Card.Title>
              <Row className='justify-content-md-center g-0'>
                { result.error && (
                  <Col xl='10'>
                    <Alert variant='danger' className='alert-danger-light'>
                      {result.error.message}
                    </Alert>
                  </Col>
                )}
                <BsForm.Group className='mb-3' as={Col} xl='10' controlId='merchant-name'>
                  <BsForm.Label>Name</BsForm.Label>
                  <BsForm.Control
                    name='name'
                    type='text'
                    defaultValue={merchant?.name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </BsForm.Group>
                <BsForm.Group className='mb-3' as={Col} xl='10' controlId='merchant-airline-agreement-number'>
                  <BsForm.Label>Airline Agreement Number</BsForm.Label>
                  <BsForm.Control
                    name='airline-agreement-number'
                    type='text'
                    defaultValue={merchant?.airlineAgreementNumber}
                    onChange={(event) => setAirlineAgreementNumber(event.target.value)}
                  />
                </BsForm.Group>
                <BsForm.Group className='mb-3' as={Col} xl='10' controlId='merchant-category-code'>
                  <BsForm.Label>Category Code</BsForm.Label>
                  <BsForm.Control
                    name='category-code'
                    type='text'
                    defaultValue={merchant?.categoryCode}
                    onChange={(event) => setCategoryCode(parseInt(event.target.value, 10))}
                  />
                </BsForm.Group>
              </Row>
            </Card.Body>
            <Card.Footer>
              <div className='d-grid col-lg-4 mx-auto'>
                <Button variant='success' type='submit' disabled={result.loading}>
                  {submitButtonLabel}
                  { result.loading && (
                    <Spinner as='span' animation='border' role='status' size='sm' className='ms-1' />
                  )}
                </Button>
              </div>
            </Card.Footer>
          </Card>
        </BsForm>
      </Col>
    </Row>
  )
}

Form.defaultProps = {
  merchant: {},
  submitButtonLabel: 'Submit'
}

export default Form
