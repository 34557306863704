import React from 'react'
import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import BsBreadcrumb from 'react-bootstrap/Breadcrumb'

function Breadcrumb() {
  const breadcrumbs = useBreadcrumbs()
  const currentCrumb = breadcrumbs.pop()

  return (
    <BsBreadcrumb>
      { breadcrumbs.map(({ match, breadcrumb }) => (
        <BsBreadcrumb.Item key={match.pathname} linkProps={{ to: match.pathname }} linkAs={Link}>
          {breadcrumb}
        </BsBreadcrumb.Item>
      ))}
      <BsBreadcrumb.Item active>{currentCrumb.breadcrumb}</BsBreadcrumb.Item>
    </BsBreadcrumb>
  )
}

export default Breadcrumb
