import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Table from '@components/pages/MiscellaneousTax/DetailsTable'
import GET_MISCELLANEOUS_DETAIL from '@graphql/queries/get-miscellaneous-tax'

function Details() {
  const { miscellaneousTaxId } = useParams()
  const [fields, setFields] = useState([])
  const EXCLUDE_FROM_FIELDS = ['__typename', 'transactionId']
  const { data, loading, error } = useQuery(GET_MISCELLANEOUS_DETAIL, {
    variables: { id: miscellaneousTaxId },
    onCompleted: (data) => setFields(Object.keys(data.miscellaneousTax).filter((key) => !EXCLUDE_FROM_FIELDS.includes(key)))
  })

  return (
    <>
      <PageHeading heading='Miscellaneous Tax Details' />

      {error ? (
        <Error error={error} />
      ) : (
        <Table
          miscellaneousTax={data?.miscellaneousTax}
          fields={fields}
          loading={loading}
        />
      )}
    </>
  )
}

export default Details
