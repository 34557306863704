import React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Table from '@components/pages/Merchant/DetailsTable'
import Toolbar from '@components/pages/Merchant/DetailsToolbar'
import toast from '@utils/toast'
import DELETE_MERCHANT from '@graphql/mutations/delete-merchant'
import GET_MERCHANT from '@graphql/queries/get-merchant'
import GET_MERCHANTS from '@graphql/queries/get-merchants'

function Details() {
  const { merchantId } = useParams()
  const navigate = useNavigate()
  const { data, loading, error } = useQuery(GET_MERCHANT, { variables: { id: merchantId } })

  const [deleteMerchant] = useMutation(DELETE_MERCHANT, {
    onCompleted() {
      navigate('/merchants/')
      toast('Merchant deleted!')
    },
    onError(error) {
      toast(`Merchant was not deleted: ${error.message}`, 'danger')
    },
    refetchQueries: [
      { query: GET_MERCHANTS, variables: { filters: {} } },
      'GetMerchants'
    ],
    update(cache, { data: { deleteMerchant } }) {
      const identity = cache.identify(deleteMerchant)
      cache.evict({ id: identity })
    }
  })

  return (
    <>
      <PageHeading
        heading='Merchant Details'
        toolbar={<Toolbar data={data} deleteMerchant={deleteMerchant} />}
      />

      {error ? (
        <Error error={error} />
      ) : (
        <Table merchant={data?.merchant} loading={loading} />
      )}
    </>
  )
}

export default Details
