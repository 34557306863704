import { gql } from '@apollo/client'

const GET_MISCELLANEOUS_DETAILS = gql`
  query GetMiscellaneousDetails($filters: JSON) {
    miscellaneousDetails(filters: $filters) {
      collection {
        id
        createdAt
        ticketDocumentNumber
        transactionId
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_MISCELLANEOUS_DETAILS
