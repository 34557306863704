import React from 'react'
import Button from 'react-bootstrap/Button'
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal'
import NavigateToButton from '@components/NavigateToButton/NavigateToButton'
import DeleteMerchantModalBody from './DeleteMerchantModalBody'

function DetailsToolbar({ data, deleteMerchant }) {
  return (
    <div>
      <NavigateToButton to='edit'>Edit</NavigateToButton>
      <ConfirmationModal
        onConfirm={() => deleteMerchant({ variables: { id: data?.merchant.id } })}
        title='Are you sure?'
        body={<DeleteMerchantModalBody name={data?.merchant.name} />}
      >
        <Button size='sm' variant='danger' className='ms-1'>
          Delete
        </Button>
      </ConfirmationModal>
    </div>
  )
}

export default DetailsToolbar
