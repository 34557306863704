import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

function ConfirmationModal({ children, onConfirm, title, body }) {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const confirm = () => {
    onConfirm()
    handleClose()
  }

  return (
    <>
      { React.cloneElement(children, { onClick: handleShow }) }

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>Cancel</Button>
          <Button variant='success' onClick={confirm}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ConfirmationModal
