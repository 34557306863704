import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Actions({ onSearch, onReset }) {
  return (
    <Form.Group as={Row} className='mb-3'>
      <Col sm={{ span: 2, offset: 2 }} className='d-grid'>
        <Button variant='primary' onClick={onSearch}>
          Search
        </Button>
      </Col>
      <Col sm='2' className='d-grid'>
        <Button variant='default' onClick={onReset}>
          Reset
        </Button>
      </Col>
    </Form.Group>
  )
}

export default Actions
