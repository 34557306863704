import React from 'react'
import Alert from 'react-bootstrap/Alert'

function Message({ message, variant }) {
  return (
    <Alert variant={variant} className='d-flex align-items-center justify-content-center'>
      {message}
    </Alert>
  )
}

Message.defaultProps = {
  variant: 'warning'
}

export default Message
