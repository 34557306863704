import { gql } from '@apollo/client'

const GET_INVOICE = gql`
  query GetInvoice($id: ID!) {
    invoice(id: $id) {
      id
      name
      createdAt
      updatedAt
      agentFirstBatchNumber
      agentLastBatchNumber
      agentNumericCode
      airlineAgreementNumber
      batchCountPerInvoice
      billingAnalysisEndingDate
      ccspExchangeRateIndicator
      ccspOptionCode
      currency
      date
      debitCreditCode
      financialRecordIndicator
      number
      rejectResubmissionIndicator
      sequenceNumber
      settlementExchangeRateSource
      totalCreditAmount
      totalCreditAmountPerAgent
      totalCreditItems
      totalCreditItemsPerAgent
      totalDebitAmount
      totalDebitAmountPerAgent
      totalDebitItems
      totalDebitItemsPerAgent
      totalDiscountAmount
      totalNetAmount
      totalTaxOnDiscountAmount
      merchant {
        id
        name
      }
    }
  }
`

export default GET_INVOICE
