import React from 'react'
import Container from 'react-bootstrap/Container'
import Navigation from '@components/Navigation/Navigation'

function NotFound() {
  return (
    <>
      <Navigation />
      <Container fluid='xl' className='py-5 text-center'>
        <h1>404 page not found</h1>
        <p>We are sorry but the page you are looking for does not exist.</p>
      </Container>
    </>
  )
}

export default NotFound
