import { gql } from '@apollo/client'

const GET_MISCELLANEOUS_DETAIL = gql`
  query GetMiscellaneousDetail($id: ID!) {
    miscellaneousDetail(id: $id) {
      id
      createdAt
      updatedAt
      attributeGroup
      attributeSubGroup
      conjunctionTicketIndicator
      couponNumber
      feeOwnerAirlineDesignator
      industryCarrierIndicator
      operatingCarrier
      reasonForIssuanceSubCode
      relatedCouponNumber
      relatedDocumentNumber
      serviceType
      ticketDocumentNumber
      transactionId
    }
  }
`

export default GET_MISCELLANEOUS_DETAIL
