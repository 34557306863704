import React from 'react'
import Breadcrumb from '@components/Breadcrumb/Breadcrumb'

function PageHeading({ heading, toolbar }) {
  return (
    <>
      <div className='d-flex align-items-center justify-content-between'>
        <h2 className='mb-0'>{heading}</h2>
        { toolbar && (
          toolbar
        )}
      </div>
      <hr className='mb-2' />
      <Breadcrumb />
    </>
  )
}

export default PageHeading
