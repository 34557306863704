import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTableCells } from '@fortawesome/pro-solid-svg-icons'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Table from 'react-bootstrap/Table'
import TableLoader from '@components/TableLoader/TableLoader'

function ListTable({ invoices, totalCount, loading }) {
  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faTableCells} fixedWidth className='me-1' />
        Invoices count: {totalCount}
      </Card.Header>
      <Table responsive bordered hover striped className='card-table text-nowrap'>
        {loading ? (
          <TableLoader span='6' />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th>Created At</th>
                <th>Name</th>
                <th>Number</th>
                <th>Date</th>
                <th>Merchant</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td>
                    <Dropdown className='d-grid'>
                      <Dropdown.Toggle variant='default' className='btn-xs'>Actions</Dropdown.Toggle>

                      <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed' }}>
                        <Dropdown.Item as={Link} to={invoice.id}>
                          <FontAwesomeIcon icon={faEye} fixedWidth className='me-1' />
                          View Details
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Link to={invoice.id}>
                      {invoice.createdAt}
                    </Link>
                  </td>
                  <td>{invoice.name}</td>
                  <td>{invoice.number}</td>
                  <td>{invoice.date}</td>
                  <td>
                    <Link to={`/merchants/${invoice.merchant.id}`}>
                      {invoice.merchant.name || invoice.merchant.id}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </Card>
  )
}

export default ListTable
