import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from '@components/Layout/Layout'
import PrivateRoute from '@components/Routes/PrivateRoute'
import ExternalAuthentication from '@pages/ExternalAuthentication/ExternalAuthentication'
import FlightDetails from '@pages/Flight/Details'
import FlightList from '@pages/Flight/List'
import InvoiceDetails from '@pages/Invoice/Details'
import InvoiceList from '@pages/Invoice/List'
import Login from '@pages/Login/Login'
import MerchantDetails from '@pages/Merchant/Details'
import MerchantEdit from '@pages/Merchant/Edit'
import MerchantList from '@pages/Merchant/List'
import MerchantNew from '@pages/Merchant/New'
import MiscellaneousDetailDetails from '@pages/MiscellaneousDetail/Details'
import MiscellaneousDetailList from '@pages/MiscellaneousDetail/List'
import MiscellaneousTaxDetails from '@pages/MiscellaneousTax/Details'
import MiscellaneousTaxList from '@pages/MiscellaneousTax/List'
import PaymentPlanDetails from '@pages/PaymentPlan/Details'
import PaymentPlanList from '@pages/PaymentPlan/List'
import TransactionBatchDetails from '@pages/TransactionBatch/Details'
import TransactionBatchList from '@pages/TransactionBatch/List'
import TransactionDetails from '@pages/Transaction/Details'
import TransactionList from '@pages/Transaction/List'
import NotFound from '@pages/NotFound/NotFound'

function AppRoutes() {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='authentication/new' element={<ExternalAuthentication />} />

      <Route element={<PrivateRoute />}>
        <Route path='/' element={<Layout />}>
          <Route index element={<TransactionList />} />

          <Route path='transactions'>
            <Route index element={<TransactionList />} />
            <Route path=':transactionId' element={<TransactionDetails />} />
          </Route>

          <Route path='merchants'>
            <Route index element={<MerchantList />} />
            <Route path=':merchantId' element={<MerchantDetails />} />
            <Route path=':merchantId/edit' element={<MerchantEdit />} />
            <Route path='new' element={<MerchantNew />} />
          </Route>

          <Route path='flights'>
            <Route index element={<FlightList />} />
            <Route path=':flightId' element={<FlightDetails />} />
          </Route>

          <Route path='invoices'>
            <Route index element={<InvoiceList />} />
            <Route path=':invoiceId' element={<InvoiceDetails />} />
          </Route>

          <Route path='miscellaneous-details'>
            <Route index element={<MiscellaneousDetailList />} />
            <Route path=':miscellaneousDetailId' element={<MiscellaneousDetailDetails />} />
          </Route>

          <Route path='miscellaneous-taxes'>
            <Route index element={<MiscellaneousTaxList />} />
            <Route path=':miscellaneousTaxId' element={<MiscellaneousTaxDetails />} />
          </Route>

          <Route path='payment-plans'>
            <Route index element={<PaymentPlanList />} />
            <Route path=':paymentPlanId' element={<PaymentPlanDetails />} />
          </Route>

          <Route path='transaction-batches'>
            <Route index element={<TransactionBatchList />} />
            <Route path=':transactionBatchId' element={<TransactionBatchDetails />} />
          </Route>
        </Route>
      </Route>

      <Route path='*' element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
