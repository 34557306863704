import { gql } from '@apollo/client'

const GET_TRANSACTION_BATCH = gql`
  query GetTransactionBatch($id: ID!) {
    transactionBatch(id: $id) {
      id
      createdAt
      updatedAt
      agentNumericCode
      billingAnalysisEndingDate
      ccspExchangeRateIndicator
      ccspInvoiceDate
      ccspInvoiceNumber
      currency
      debitCreditCode
      financialRecordIndicator
      invoiceDate
      invoiceName
      invoiceNumber
      isoCountryCode
      number
      placeOfIssue
      pointOfSaleName
      rejectResubmissionIndicator
      settlementExchangeRate
      settlementExchangeRateDate
      settlementExchangeRateSource
      totalCreditAmount
      totalCreditItems
      totalDebitAmount
      totalDebitCreditAmountSettlement
      totalDebitCreditSignedForAmount
      totalDebitItems
      totalNumberOfItems
      invoice {
        id
        name
      }
    }
  }
`

export default GET_TRANSACTION_BATCH
