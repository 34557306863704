import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTableCells } from '@fortawesome/pro-solid-svg-icons'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Table from 'react-bootstrap/Table'
import TableLoader from '@components/TableLoader/TableLoader'

function ListTable({ paymentPlans, totalCount, loading }) {
  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faTableCells} fixedWidth className='me-1' />
        Payment Plans count: {totalCount}
      </Card.Header>
      <Table responsive bordered hover striped className='card-table text-nowrap'>
        {loading ? (
          <TableLoader span='7' />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th>Created At</th>
                <th>Ticket Document Number</th>
                <th>Invoice Number</th>
                <th>Currency</th>
                <th>Total Amount</th>
                <th>Transaction</th>
              </tr>
            </thead>
            <tbody>
              {paymentPlans.map((paymentPlan) => (
                <tr key={paymentPlan.id}>
                  <td>
                    <Dropdown className='d-grid'>
                      <Dropdown.Toggle variant='default' className='btn-xs'>Actions</Dropdown.Toggle>

                      <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed' }}>
                        <Dropdown.Item as={Link} to={paymentPlan.id}>
                          <FontAwesomeIcon icon={faEye} fixedWidth className='me-1' />
                          View Details
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Link to={paymentPlan.id}>
                      {paymentPlan.createdAt}
                    </Link>
                  </td>
                  <td>{paymentPlan.ticketDocumentNumber}</td>
                  <td>{paymentPlan.invoiceNumber}</td>
                  <td>{paymentPlan.currency}</td>
                  <td>{paymentPlan.totalAmount}</td>
                  <td>
                    <Link to={`/transactions/${paymentPlan.transactionId}`}>
                      {paymentPlan.transactionId}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </Card>
  )
}

export default ListTable
