import { gql } from '@apollo/client'

const MERCHANT_DETAILS_FIELDS = gql`
  fragment MerchantDetailsFields on Merchant {
    id
    name
    createdAt
    updatedAt
    airlineAgreementNumber
    categoryCode
  }
`

export default MERCHANT_DETAILS_FIELDS
