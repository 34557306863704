import { gql } from '@apollo/client'

const GET_TRANSACTION = gql`
  query GetTransaction($id: ID!) {
    transaction(id: $id) {
      id
      createdAt
      updatedAt
      agentNumericCode
      amount
      approvalCode
      authorisedAmount
      batchNumber
      billingAnalysisEndingDate
      cardAuthenticationInformation
      cardAuthenticationSequenceNumber
      cardVerificationValueResult
      ccspExchangeRateIndicator
      ccspInvoiceDate
      ccspInvoiceNumber
      creditCardAccountNumber
      creditCardCode
      currency
      customerFileReference
      dateOfIssue
      debitCreditCode
      eightyByteStatus
      expiryDate
      extendedPaymentCode
      financialRecordIndicator
      flightDepartureDate
      formOfPaymentTransaction
      invoiceDate
      invoiceNumber
      isoCountryCode
      passengerName
      passengerSpecificData
      placeOfIssue
      pointOfSaleName
      reasonForIssuanceCode
      referenceCode
      rejectResubmissionIndicator
      relatedTicketDocumentNumber
      routingDomesticInternationalIndicator
      settlementDebitCreditAmount
      settlementExchangeRate
      settlementExchangeRateDate
      settlementExchangeRateSource
      sourceOfApprovalCode
      statisticalCode
      status
      supplementalCreditCardTransactionData
      threeDAuthenticationAndAdditionalCardPaymentInfo
      ticketDocumentNumber
      timeOfIssue
      transactionCode
      transactionBatch {
        id
        number
      }
    }
  }
`

export default GET_TRANSACTION
