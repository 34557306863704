import { gql } from '@apollo/client'

const GET_TRANSACTION_BATCHES = gql`
  query GetTransactionBatches($filters: JSON) {
    transactionBatches(filters: $filters) {
      collection {
        id
        createdAt
        currency
        number
        invoice {
          id
          name
        }
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_TRANSACTION_BATCHES
