/*
 * This service is responsible for the initializing the initial connection to
 * the main Authentication service (In our case - Genesis - Admin PCI console).
 */

const ExternalAuthentication = {
  redirect: () => {
    console.warn('[ExternalAuthentication.redirect]')

    global.location.replace(`
      ${Settings.OAUTH_AUTHENTICATION_URL}?
      client_id=${Settings.OAUTH_CLIENT_ID}&
      response_type=${Settings.OAUTH_RESPONSE_TYPE}&
      redirect_uri=${Settings.OAUTH_INTERNAL_REDIRECT_URI}
    `.replace(/\s+/g, ''))
  }
}

export default ExternalAuthentication
