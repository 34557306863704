import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import Accordion from 'react-bootstrap/Accordion'

function Search({ searchFilters, children }) {
  const eventKey = '0'
  const defaultActiveKey = Object.keys(searchFilters).length === 0 ? '' : eventKey

  return (
    <Accordion defaultActiveKey={defaultActiveKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header as='div'>
          <FontAwesomeIcon icon={faMagnifyingGlass} fixedWidth className='me-1' />
          Search
        </Accordion.Header>
        <Accordion.Body>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default Search
