import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Pagination from '@components/Pagination/Pagination'
import Search from '@components/pages/MiscellaneousDetail/Search'
import Table from '@components/pages/MiscellaneousDetail/ListTable'
import GET_MISCELLANEOUS_DETAILS from '@graphql/queries/get-miscellaneous-details'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, loading, error, refetch } = useQuery(GET_MISCELLANEOUS_DETAILS, {
    variables: { filters: searchFilters }
  })

  return (
    <>
      <PageHeading heading='Miscellaneous Details' />

      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.miscellaneousDetails?.metadata}
          />
          <Table
            totalCount={data?.miscellaneousDetails?.metadata?.totalCount}
            miscellaneousDetails={data?.miscellaneousDetails?.collection || []}
            loading={loading}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.miscellaneousDetails?.metadata}
          />
        </>
      )}
    </>
  )
}

export default List
