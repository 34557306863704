import { gql } from '@apollo/client'

const GET_TRANSACTIONS = gql`
  query GetTransactions($filters: JSON) {
    transactions(filters: $filters) {
      collection {
        id
        amount
        createdAt
        currency
        eightyByteStatus
        isoCountryCode
        referenceCode
        status
        transactionCode
        transactionBatch {
          id
          number
        }
      }
      metadata {
        totalCount
        currentPage
        limitValue
      }
    }
  }
`

export default GET_TRANSACTIONS
