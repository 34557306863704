import { gql } from '@apollo/client'
import MERCHANT_DETAILS_FIELDS from '@graphql/fragments/merchant-details-fields'

const CREATE_MERCHANT = gql`
  ${MERCHANT_DETAILS_FIELDS}
  mutation CreateMerchant($name: String!, $airlineAgreementNumber: String!, $categoryCode: Int!) {
    createMerchant(name: $name, airlineAgreementNumber: $airlineAgreementNumber, categoryCode: $categoryCode) {
      ...MerchantDetailsFields
    }
  }
`

export default CREATE_MERCHANT

