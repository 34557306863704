import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import Error from '@components/Error/Error'
import NavigateToButton from '@components/NavigateToButton/NavigateToButton'
import PageHeading from '@components/PageHeading/PageHeading'
import Pagination from '@components/Pagination/Pagination'
import Search from '@components/pages/Merchant/Search'
import Table from '@components/pages/Merchant/ListTable'
import toast from '@utils/toast'
import DELETE_MERCHANT from '@graphql/mutations/delete-merchant'
import GET_MERCHANTS from '@graphql/queries/get-merchants'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, loading, error, refetch } = useQuery(GET_MERCHANTS, {
    variables: { filters: searchFilters }
  })

  const [deleteMerchant] = useMutation(DELETE_MERCHANT, {
    onCompleted() {
      toast('Merchant deleted!')
    },
    onError(error) {
      toast(`Merchant was not deleted: ${error.message}`, 'danger')
    },
    refetchQueries: [
      { query: GET_MERCHANTS, variables: { filters: searchFilters } },
      'GetMerchants'
    ],
    update(cache, { data: { deleteMerchant } }) {
      const identity = cache.identify(deleteMerchant)
      cache.evict({ id: identity })
    }
  })

  return (
    <>
      <PageHeading
        heading='Merchants'
        toolbar={<NavigateToButton to='new'>New merchant</NavigateToButton>}
      />

      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.merchants?.metadata}
          />
          <Table
            totalCount={data?.merchants?.metadata?.totalCount}
            merchants={data?.merchants?.collection || []}
            deleteMerchant={deleteMerchant}
            loading={loading}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.merchants?.metadata}
          />
        </>
      )}
    </>
  )
}

export default List
