import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Table from '@components/pages/Invoice/DetailsTable'
import GET_INVOICE from '@graphql/queries/get-invoice'

function Details() {
  const { invoiceId } = useParams()
  const [fields, setFields] = useState([])
  const EXCLUDE_FROM_FIELDS = ['__typename', 'merchant']
  const { data, loading, error } = useQuery(GET_INVOICE, {
    variables: { id: invoiceId },
    onCompleted: (data) => setFields(Object.keys(data.invoice).filter((key) => !EXCLUDE_FROM_FIELDS.includes(key)))
  })

  return (
    <>
      <PageHeading heading='Invoice Details' />

      {error ? (
        <Error error={error} />
      ) : (
        <Table
          invoice={data?.invoice}
          fields={fields}
          loading={loading}
        />
      )}
    </>
  )
}

export default Details
