import React from 'react'
import Form from 'react-bootstrap/Form'

function DatetimeField({ name, value, onChange }) {
  return (
    <Form.Control
      name={name}
      value={value}
      type='datetime-local'
      pattern='[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}'
      onChange={(event) => onChange(name, event.target.value)}
    />
  )
}

export default DatetimeField
