import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

function TableLoader({ span }) {
  return (
    <tbody>
      <tr>
        <td colSpan={span} className='text-center'>
          <Spinner animation='border' role='status' className='my-2' data-testid='loading-spinner' />
        </td>
      </tr>
    </tbody>
  )
}

TableLoader.defaultProps = {
  span: ''
}

export default TableLoader
