import React from 'react'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import TableLoader from '@components/TableLoader/TableLoader'
import camelCaseToTitle from '@utils/camel-case-to-title'

function DetailsTable({ paymentPlan, fields, loading }) {
  return (
    <Card>
      <Card.Header>Payment Plan Details</Card.Header>
      <Table responsive bordered hover striped className='card-table'>
        {loading ? (
          <TableLoader />
        ) : (
          <tbody>
            { fields.map((field) => (
              <tr key={field}>
                <th className='col-lg-3'>{camelCaseToTitle(field)}</th>
                <td>{paymentPlan[field]}</td>
              </tr>
            ))}
            <tr>
              <th className='col-lg-3'>Transaction</th>
              <td>
                <Link to={`/transactions/${paymentPlan.transactionId}`}>
                  {paymentPlan.transactionId}
                </Link>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </Card>
  )
}

export default DetailsTable
