import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faPencil, faTableCells, faXmark } from '@fortawesome/pro-solid-svg-icons'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Table from 'react-bootstrap/Table'
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal'
import TableLoader from '@components/TableLoader/TableLoader'
import DeleteMerchantModalBody from './DeleteMerchantModalBody'

function ListTable({ merchants, totalCount, loading, deleteMerchant }) {
  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faTableCells} fixedWidth className='me-1' />
        Merchants count: {totalCount}
      </Card.Header>
      <Table responsive bordered hover striped className='card-table text-nowrap'>
        {loading ? (
          <TableLoader span='4' />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th>Name</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {merchants.map((merchant) => (
                <tr key={merchant.id}>
                  <td>
                    <Dropdown className='d-grid'>
                      <Dropdown.Toggle variant='default' className='btn-xs'>Actions</Dropdown.Toggle>

                      <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed' }}>
                        <Dropdown.Item as={Link} to={merchant.id}>
                          <FontAwesomeIcon icon={faEye} fixedWidth className='me-1' />
                          View Details
                        </Dropdown.Item>
                        <Dropdown.Item as={Link} to={`${merchant.id}/edit`}>
                          <FontAwesomeIcon icon={faPencil} fixedWidth className='me-1' />
                          Edit
                        </Dropdown.Item>
                        <ConfirmationModal
                          onConfirm={() => deleteMerchant({ variables: { id: merchant.id } })}
                          title='Are you sure?'
                          body={<DeleteMerchantModalBody name={merchant.name} />}
                        >
                          <Dropdown.Item as='button'>
                            <FontAwesomeIcon icon={faXmark} fixedWidth className='me-1 text-danger' />
                            Delete
                          </Dropdown.Item>
                        </ConfirmationModal>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Link to={merchant.id}>
                      {merchant.name}
                    </Link>
                  </td>
                  <td>{merchant.createdAt}</td>
                  <td>{merchant.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </Card>
  )
}

export default ListTable
