import React from 'react'
import ReactJsPagination from 'react-js-pagination'

function Pagination({ pageMetadata, onPageChange, pageRange }) {
  /* We need dummy total count value for the initial load */
  const { currentPage, limitValue, totalCount = 30 } = pageMetadata

  return (
    <ReactJsPagination
      activePage={currentPage}
      itemsCountPerPage={limitValue}
      totalItemsCount={totalCount}
      pageRangeDisplayed={pageRange}
      innerClass='pagination mt-5 mb-4'
      itemClass='page-item'
      linkClass='page-link'
      firstPageText='« First'
      prevPageText='‹ Prev'
      nextPageText='Next ›'
      lastPageText='Last »'
      onChange={(page) => onPageChange(page)}
    />
  )
}

Pagination.defaultProps = {
  pageMetadata: {
    currentPage: 1
  },
  pageRange: 5
}

export default Pagination
