import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Table from '@components/pages/TransactionBatch/DetailsTable'
import GET_TRANSACTION_BATCH from '@graphql/queries/get-transaction-batch'

function Details() {
  const { transactionBatchId } = useParams()
  const [fields, setFields] = useState([])
  const EXCLUDE_FROM_FIELDS = ['__typename', 'invoice']
  const { data, loading, error } = useQuery(GET_TRANSACTION_BATCH, {
    variables: { id: transactionBatchId },
    onCompleted: (data) => setFields(Object.keys(data.transactionBatch).filter((key) => !EXCLUDE_FROM_FIELDS.includes(key)))
  })

  return (
    <>
      <PageHeading heading='Transaction Batch Details' />

      {error ? (
        <Error error={error} />
      ) : (
        <Table
          transactionBatch={data?.transactionBatch}
          fields={fields}
          loading={loading}
        />
      )}
    </>
  )
}

export default Details
