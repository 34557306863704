import React, { useEffect } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import ExternalAuthentication from '@utils/external-authentication'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'

function Login() {
  useEffect(() => {
    ExternalAuthentication.redirect()
  }, [])

  return (
    <>
      <Navbar expand='lg' bg='dark' variant='dark' style={{ height: '58px' }}>
        <Container fluid='xl'>
          <Navbar.Brand>BSP Processor</Navbar.Brand>
        </Container>
      </Navbar>
      <Container fluid='xl' className='py-5'>
        <div className='d-flex justify-content-center'>
          <Spinner animation='border' role='status' data-testid='loading-spinner' className='my-2' />
        </div>
      </Container>
    </>
  )
}

export default Login
