import React from 'react'
import Card from 'react-bootstrap/Card'
import Table from 'react-bootstrap/Table'
import TableLoader from '@components/TableLoader/TableLoader'

function DetailsTable({ merchant, loading }) {
  return (
    <Card>
      <Card.Header>Merchant Details</Card.Header>
      <Table responsive bordered hover striped className='card-table'>
        {loading ? (
          <TableLoader />
        ) : (
          <tbody>
            <tr>
              <th className='col-lg-3'>ID</th>
              <td>{merchant.id}</td>
            </tr>
            <tr>
              <th className='col-lg-3'>Name</th>
              <td>{merchant.name}</td>
            </tr>
            <tr>
              <th className='col-lg-3'>Airline Agreement Number</th>
              <td>{merchant.airlineAgreementNumber}</td>
            </tr>
            <tr>
              <th className='col-lg-3'>Category Code</th>
              <td>{merchant.categoryCode}</td>
            </tr>
            <tr>
              <th className='col-lg-3'>Created At</th>
              <td>{merchant.createdAt}</td>
            </tr>
            <tr>
              <th className='col-lg-3'>Updated At</th>
              <td>{merchant.updatedAt}</td>
            </tr>
          </tbody>
        )}
      </Table>
    </Card>
  )
}

export default DetailsTable
