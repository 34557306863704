import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Error from '@components/Error/Error'
import PageHeading from '@components/PageHeading/PageHeading'
import Pagination from '@components/Pagination/Pagination'
import Search from '@components/pages/Flight/Search'
import Table from '@components/pages/Flight/ListTable'
import GET_FLIGHTS from '@graphql/queries/get-flights'

function List() {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchFilters = Object.fromEntries(searchParams)
  const { data, loading, error, refetch } = useQuery(GET_FLIGHTS, {
    variables: { filters: searchFilters }
  })

  return (
    <>
      <PageHeading heading='Flights' />

      {error ? (
        <Error error={error} />
      ) : (
        <>
          <Search
            refetch={refetch}
            searchFilters={searchFilters}
            setSearchParams={setSearchParams}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.flights?.metadata}
          />
          <Table
            totalCount={data?.flights?.metadata?.totalCount}
            flights={data?.flights?.collection || []}
            loading={loading}
          />
          <Pagination
            onPageChange={(page) => setSearchParams({ ...searchFilters, page })}
            pageMetadata={data?.flights?.metadata}
          />
        </>
      )}
    </>
  )
}

export default List
