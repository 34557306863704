import React from 'react'
import { toast as reactToastifyToast } from 'react-toastify'
import Message from './Message'

function toast(message, variant) {
  return (
    reactToastifyToast(<Message message={message} variant={variant} />)
  )
}

export default toast
