import { gql } from '@apollo/client'

const DELETE_MERCHANT = gql`
  mutation deleteMerchant($id: ID!) {
    deleteMerchant(id: $id) {
      id
    }
  }
`

export default DELETE_MERCHANT

