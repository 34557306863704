import { gql } from '@apollo/client'

const GET_PAYMENT_PLAN = gql`
  query GetPaymentPlan($id: ID!) {
    paymentPlan(id: $id) {
      id
      createdAt
      updatedAt
      code
      currency
      downPaymentAmount
      installmentAmount
      installmentQuantity
      invoiceNumber
      paymentGroupMainDocument
      refundAmount
      relatedDocumentIssueDate
      taxesAmount
      ticketDocumentNumber
      totalAmount
      turnaroundCityAirportCode
      transactionId
    }
  }
`

export default GET_PAYMENT_PLAN
