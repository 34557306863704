import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTableCells } from '@fortawesome/pro-solid-svg-icons'
import Card from 'react-bootstrap/Card'
import Dropdown from 'react-bootstrap/Dropdown'
import Table from 'react-bootstrap/Table'
import TableLoader from '@components/TableLoader/TableLoader'

function ListTable({ transactions, totalCount, loading }) {
  return (
    <Card>
      <Card.Header>
        <FontAwesomeIcon icon={faTableCells} fixedWidth className='me-1' />
        Transactions count: {totalCount}
      </Card.Header>
      <Table responsive bordered hover striped className='card-table text-nowrap'>
        {loading ? (
          <TableLoader span='10' />
        ) : (
          <>
            <thead>
              <tr>
                <th className='table-col-sm'>Action</th>
                <th>Created At</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Transaction Code</th>
                <th>Reference Code</th>
                <th>Iso Country code</th>
                <th>Eighty Byte Status</th>
                <th>Transaction Batch</th>
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction) => (
                <tr key={transaction.id}>
                  <td>
                    <Dropdown className='d-grid'>
                      <Dropdown.Toggle variant='default' className='btn-xs'>Actions</Dropdown.Toggle>

                      <Dropdown.Menu renderOnMount popperConfig={{ strategy: 'fixed' }}>
                        <Dropdown.Item as={Link} to={transaction.id}>
                          <FontAwesomeIcon icon={faEye} fixedWidth className='me-1' />
                          View Details
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td>
                    <Link to={transaction.id}>
                      {transaction.createdAt}
                    </Link>
                  </td>
                  <td>{transaction.status}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.currency}</td>
                  <td>{transaction.transactionCode}</td>
                  <td>{transaction.referenceCode}</td>
                  <td>{transaction.isoCountryCode}</td>
                  <td>{transaction.eightyByteStatus}</td>
                  <td>
                    <Link to={`/transaction-batches/${transaction.transactionBatch.id}`}>
                      {transaction.transactionBatch.number || transaction.transactionBatch.id}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </Table>
    </Card>
  )
}

export default ListTable
