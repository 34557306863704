import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

function NavigateToButton({ children, variant, size, to }) {
  const navigate = useNavigate()

  return (
    <Button size={size} variant={variant} onClick={() => navigate(to)}>
      {children}
    </Button>
  )
}

NavigateToButton.defaultProps = {
  variant: 'primary',
  size: 'sm'
}

export default NavigateToButton
